.puupyFlex {
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
  .ppyImg {
    flex: 0 0 50%;
    @media screen and (max-width: 900px) {
      width: 100vw;
      height: auto;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding: 4rem;
      @media screen and (max-width: 900px) {
        padding: 0;
      }
    }
  }
  .puppyText {
    flex: 0 0 50%;
    padding: 3rem 0;
    padding-top: 2rem;
    h1 {
      font-weight: 900;
      font-size: 3rem;
      line-height: 60px;
      font-style: italic;
      @media screen and (max-width: 900px) {
        font-size: 1.8rem;
        line-height: 40px;
        text-align: center;
      }
    }
    p {
      font-family: "Lora", serif;
      font-size: 1.3rem;
      @media screen and (max-width: 900px) {
        font-size: 1.2rem;
        text-align: center;
      }
    }
  }
}
