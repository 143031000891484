* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

*::-webkit-scrollbar {
  width: 5px;
}
*::-moz-range-track,
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}
::-moz-range-thumb,
audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
  display: none;
}
audio::-webkit-media-controls,
video::-webkit-media-controls {
  display: none;
}
video::-ms-media-controls {
  display: none !important;
}
body {
  font-family: "Source Sans Pro", sans-serif;
  // overflow-x: hidden;
}
.containerSelf {
  padding: 0 6rem;
  @media screen and (max-width: 900px) {
    padding: 0 1rem;
  }
}

button {
  outline: none;
  background: transparent;
  margin-top: 1rem;
  color: black;
  cursor: pointer;
  height: 49px;
  width: 175px;
  padding: 0 1rem;
  font-size: 1.3rem;
  border: 2px solid black;
  a {
    color: white;
    text-decoration: none;
    &:hover {
      color: white;
    }
  }

  @media screen and (max-width: 900px) {
    // margin-top: 1rem;
    width: auto;
    font-size: 20px;
  }
}
// .about,
// .contact {
//   background: #f2f7fc;
// }

// Import all scss file
@import "./navbar";

// Footer scss file
@import "./footer";

// Home scss file
@import "./Home/homeOne";
@import "./Home/homeTwo";
@import "./Home/homeThree";
@import "./Home/homeService";
@import "./Home/homeQuality";
@import "./Home/homeGif";
@import "./Home/homeClient";
@import "./Home/homeFour";

// About us page
@import "./AboutUs/aboutHome";
@import "./AboutUs/aboutTeam";
@import "./AboutUs//aboutJoin";
@import "./AboutUs/aboutPuppy";

// Contact us page
@import "./contact/contactHome";
@import "./contact/contactLocation";

// Servive page
@import "./Service/serviceHome";
@import "./Service/slider";
