.serviveHome {
  height: 100vh;
  width: 100%;
  @media screen and (max-width: 800px){
    height: auto;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
