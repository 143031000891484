.houseService {
  margin-top: 6rem;
  margin-bottom: 8rem;
  @media screen and (max-width: 800px) {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  h1 {
    font-weight: 900;
    text-align: center;
  }
  p {
    text-align: center;
    font-family: "Lora", serif;
    font-size: 1.3rem;
    @media screen and (max-width: 800px) {
      font-size: 1rem;
    }
  }
}
.carousel-item {
  height: 650px;
  background: grey;
  @media screen and (max-width: 800px) {
    height: 250px;
  }
  img {
    height: 100%;
    object-fit: cover;
  }
}
.fullSize {
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.smallSize {
  display: none;
  @media screen and (max-width: 700px) {
    display: block;
  }
  .imgSlide {
    width: 100%;
    height: 250px;
    margin: 2rem 0;
    background: grey;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
.carousel-indicators {
  bottom: -60px !important;
}
.carousel {
  margin-bottom: 5rem;
}
.carousel-control-prev-icon {
  position: relative;
  left: -130px;
}

.carousel-control-next-icon {
  position: relative;
  right: -130px;
}
