.aboutTeam {
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 3rem;
    font-weight: 700;
    @media screen and (max-width: 900px) {
      font-size: 1.8rem;
    }
  }
  p {
    font-family: "Lora", serif;
    text-align: center;
    margin-bottom: 0;
  }
  .personBox {
    width: 890px;
    height: 650px;
    margin-top: 6rem;
    .founderImage {
      background-color: lightgrey;
      background: url(../../assets/about/founder.png) no-repeat center
        center/cover;
      object-fit: cover;
      width: 100%;
      height: 97%;
      cursor: pointer;
      transition: all 0.3s ease-in;
      @media screen and (min-width: 900px) {
        &:hover {
          background: url(../../assets/about/founderHover.png) no-repeat center
            center/cover;
        }
      }
      @media screen and (max-width: 900px) {
        height: 100%;
      }
    }
    .personName {
      background-color: #e1ebf5;
      text-align: center;
      padding: 0.8rem;
      margin-top: 0.8rem;
      h1 {
        font-size: 1.5rem;
        margin: 0;
        padding: 0;
      }
      p {
        margin: 0;
        padding: 0;
        font-family: "Lora", serif;
      }
      @media screen and (max-width: 600px) {
        margin-top: 0.5rem;
      }
    }
    @media screen and (max-width: 900px) {
      width: 345px;
      height: 400px;
      margin-top: 2rem;
    }
  }
  .topMen {
    margin-top: 1rem;
  }
  .secondRow {
    display: flex;
    height: 550px;
    .flexImageWin {
      flex: 0 0 50%;
      padding: 1rem;
      .ceoImage {
        background: url(../../assets/about/ceo.png) no-repeat center
          center/cover;
        @media screen and (min-width: 900px) {
          &:hover {
            background: url(../../assets/about/ceoHover.png) no-repeat center
              center/cover;
          }
        }
      }
      .cooImage {
        background: url(../../assets/about/coo.png) no-repeat center
          center/cover;
        @media screen and (min-width: 900px) {
          &:hover {
            background: url(../../assets/about/cooHover.png) no-repeat center
              center/cover;
          }
        }
        @media screen and (max-width: 900px) {
          margin-top: 2rem;
        }
      }
      @media screen and (max-width: 900px) {
        padding: 0;
        padding-top: 1rem;
        margin-top: 4rem;
      }
    }
    .thirdRow {
      flex: 0 0 33.33%;
      height: 370px;
      
      .mdImage {
        background: url(../../assets/about/md.png) no-repeat center center/cover;
        @media screen and (min-width: 900px) {
          &:hover {
            background: url(../../assets/about/mdhover.png) no-repeat center
              center/cover;
          }
        }
      }
      .hodImage {
        background: url(../../assets/about/hod.png) no-repeat center
          center/cover;
        @media screen and (min-width: 900px) {
          &:hover {
            background: url(../../assets/about/hodhover.png) no-repeat center
              center/cover;
          }
        }
      }
      .poImage {
        background: url(../../assets/about/po.png) no-repeat center center/cover;
        @media screen and (min-width: 900px) {
          &:hover {
            background: url(../../assets/about/pohover.png) no-repeat center
              center/cover;
          }
        }
      }
      @media screen and (max-width: 900px) {
        margin-top: 6rem;
        flex: unset;
      }
    }
    .pdl0 {
      padding-left: 0;
    }
    .pdr0 {
      padding-right: 0;
    }
    @media screen and (max-width: 900px) {
      flex-direction: column;
      height: 812px;
    }
  }
  .thirdOne {
    height: auto;
    margin-top: 5.5rem;
    @media screen and (max-width: 900px) {
      // height: 1200px;
      margin-top: 10rem;
    }
  }
}
