.homeService {
  margin-top: 6rem;
  text-align: center;
  h1 {
    font-size: 2.6rem;
    font-weight: 900;
    text-align: center;
    @media screen and (max-width: 650px) {
      font-size: 2rem;
    }
  }
  .servicesFlex {
    display: grid;
    padding-top: 2rem;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
    position: relative;
    @media screen and (max-width: 650px) {
      grid-template-columns: 100%;
    }
    .imgFlex {
      height: 470px;
      background: lightgray;
      @media screen and (max-width: 650px) {
        height: 240px;
        margin-top: 2rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media screen and (max-width: 600px) {
        margin-top: 1rem;
      }
    }
    .one {
      grid-row: 1/3;
      @media screen and (max-width: 650px) {
        grid-row: unset;
      }
    }
    .three {
      grid-row: 2/4;
      @media screen and (max-width: 650px) {
        grid-row: 3/5;
      }
    }
    .titleFlex {
      font-style: italic;
      height: 150px;
      font-weight: 700;
      font-size: 2.25rem;
      line-height: 50px;
      display: flex;
      align-items: center;
      padding: 2rem;
      @media screen and (max-width: 650px) {
        height: 100px;
        justify-content: center !important;
        margin-top: 0.5rem;
      }
    }
    @media screen and (max-width: 600px) {
      padding-top: 0;
      grid-gap: 0;
    }
  }
  button{
    margin-top: 3rem;
  }
  @media screen and (max-width: 600px) {
    margin-top: 4rem;
  }
}
