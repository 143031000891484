.aboutHome {
  img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    @media screen and (max-width: 1000px) {
      height: auto;
      object-fit: contain;
    }
  }
  p {
    font-style: italic;
    font-weight: 900;
    font-size: 2.5rem;
    line-height: 60px;
    text-align: center;
    padding-top: 2rem;
    @media screen and (max-width: 900px) {
      font-size: 1.8rem;
      line-height: 40px;
    }
  }
}
