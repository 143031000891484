.aboutJoin {
  background: #e1ebf5;
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-top: 8rem;
  @media screen and (max-width: 900px) {
    margin-top: 10rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  h1 {
    font-size: 3.2rem;
    padding: 1rem;
    font-weight: 900;
    @media screen and (max-width: 900px) {
      font-size: 1.75rem;
    }
  }
  p {
    font-size: 1.5rem;
    font-family: "Lora", serif;
    @media screen and (max-width: 900px) {
      font-size: 1.2rem;
    }
  }
  button {
    background: linear-gradient(
      164.53deg,
      #080808 36.99%,
      #171b22 64.11%,
      #222832 89.6%
    );
    color: white;
  }
}
