.contactHome {
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    @media screen and (max-width: 900px) {
      height: auto;
      object-fit: contain;
    }
  }
  // &::before {
  //   background: url(../../assets/contact/back.png);
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   content: "";
  //   height: 100vh;
  //   width: 100vw;
  //   z-index: -1;
  // }
}
.formData {
  width: 708px;
  height: 100vh;
  margin: auto;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 900px) {
    position: unset;
    width: 100vw;
    height: auto;
  }
  form {
    width: 100%;
    margin: auto;
    background: #e1ebf5;
    padding: 2rem;
    h1 {
      margin: 0;
      font-size: 2.25rem;
      font-weight: 900;
    }
    p {
      font-size: 1.2rem;
      margin: 0;
      font-family: "Lora", serif;
      // line-height: 50px;
    }
    button {
      background: linear-gradient(
        164.53deg,
        #080808 36.99%,
        #171b22 64.11%,
        #222832 89.6%
      );
      color: white;
      margin-top: 1.5rem;
    }
    @media screen and (max-width: 600px) {
      text-align: center;
    }
  }
}
input[type="submit"] {
  background: transparent;
  border: 2px solid #000;
  cursor: pointer;
  font-size: 1.3rem;
  height: 49px;
  padding: 0 1rem;
  width: 175px;
  margin-top: 1.5rem;
  background: linear-gradient(
    164.53deg,
    #080808 36.99%,
    #171b22 64.11%,
    #222832 89.6%
  );
  color: white;
  outline: none;
  padding: 0.5rem 1rem;
}
