.contactLocation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    text-align: center;
  }
  .locationText {
    flex: 0 0 50%;
    // line-height: 50px;
    .mail,
    .cellNo {
      a {
        display: flex;
        align-items: center;
        color: black;
        text-decoration: none;
        // line-height: 40px;
        font-size: 1.2rem;
        font-family: "Lora", serif;
        @media screen and (max-width: 1100px) {
          justify-content: center;
        }
        img {
          padding-right: 10px;
        }
      }
    }
    h1 {
      font-size: 3.75rem;
      line-height: 80px;
      padding-bottom: 1.5rem;
      font-weight: 900;
      @media screen and (max-width: 1100px) {
        font-size: 2rem;
        line-height: 40px;
        padding-bottom: 0.5rem;
      }
    }
  }
  .map {
    padding: 2rem;
    flex: 0 0 50%;
    h1 {
      line-height: 80px;
      font-weight: 900;
      @media screen and (max-width: 1100px) {
        font-size: 2rem !important;
        line-height: 40px;
        padding-bottom: 0.5rem !important;
      }
    }
    .loc {
      display: flex;
      align-items: flex-start;
      img {
        padding-right: 0.5rem;
        position: relative;
        top: 5px;
        @media screen and (max-width: 1100px) {
          display: none;
        }
      }
      p {
        font-size: 1.1rem;
        font-family: "Lora", serif;
        line-height: 32px;
        img {
          display: none;
          @media screen and (max-width: 1100px) {
            display: unset;
            position: relative;
            top: 0px;
            padding: 0;
          }
        }
      }
    }
    iframe {
      width: 100%;
      @media screen and (max-width: 1100px) {
        width: 80vw;
        height: 260px;
        padding-top: 0rem;
      }
    }
    @media screen and (max-width: 1100px) {
      padding-top: 0rem;
    }
    h1 {
      font-size: 3.75rem;
      padding-bottom: 1.5rem;
    }
  }
}
.locBack {
  background: #e1ebf5;
  line-height: 40px;
  padding: 2rem 6rem;
  @media screen and (max-width: 900px) {
    margin-top: 0;
    padding: 2rem;
    margin-top: 2rem;
  }
}
.finding {
  padding-top: 2rem !important;
}
