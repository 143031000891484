.mpplYellow {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  // align-items: center;
  height: 100vh;
  margin-top: 6rem;
  .backYellowImage::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background: url(../../assets/mpplYellow.jpg) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  p {
    font-style: italic;
    font-weight: 900;
    font-size: 3.2rem;
    line-height: 75px;
    color: #ffffff;
    width: 80%;
    padding-top: 4rem;
    @media screen and (max-width: 600px) {
      font-size: 1.8rem;
      color: black;
      text-align: center;
      line-height: 50px;
      width: 95%;
    }
  }
  @media screen and (max-width: 600px){
    margin-top: 4rem;
    align-items: center;
  }
}
.mobileQuote {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 900px) {
    margin-bottom: 4rem;
  }

  p {
    font-style: italic;
    font-weight: 900;
    font-size: 1.8rem;
    line-height: 40px;
    text-align: center;
    padding-top: 2rem;
    display: none;
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
  button {
    display: none;
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
}
