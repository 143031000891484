.homeTwo {
  position: relative;
  height: 85vh;
  top: -7px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    height: 40vh;
  }
  .backImage::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background: url(../../assets/homeTwoback.jpg) no-repeat center center/cover;
    height: 77%;
    width: 100%;
    z-index: -1;
    @media screen and (max-width: 800px) {
      height: 35vh;
    }
  }
  img {
    object-fit: cover;
    width: 630px;
    // height: 83vh;
    position: relative;
    top: 35px;
    @media screen and (max-width: 800px) {
      height: 28vh;
      top: 70px;
      width: 80vw;
    }
  }
}
.textSide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 800px) {
    padding-top: 2rem;
  }
  button {
    a {
      color: black;
    }
  }
  h1 {
    font-weight: 900;
    font-size: 2.6rem;
    line-height: 52px;
    @media screen and (max-width: 800px) {
      font-size: 1.8rem;
    }
  }
  p {
    font-size: 1.3rem;
    padding-top: 1rem;
    font-family: "Lora", serif;
    line-height: 30px;
    text-align: center;
    @media screen and (max-width: 800px) {
      font-size: 1rem;
      padding-top: 0;
    }
  }
}
