nav {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
  height: 60px;
  @media screen and (max-width: 1000px) {
    justify-content: center;
    align-items: center;
    height: 13vh;
    position: sticky;
  }
  .Navlogo {
    display: flex;
    justify-content: center;
    /* flex-direction: row; */
    align-items: center;
    height: 100%;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      height: 55px;
      margin-top: 0.5rem;
    }
    a {
      height: 75%;
      color: white;
      text-decoration: none;
    }
    img {
      height: 100%;

    }
    h1 {
      font-size: 2rem;
      font-weight: 900;
      color: #fff;
      margin-left: 1rem;
      margin-bottom: 0;
      @media screen and (max-width: 1000px) {
        margin-left: 0;
        font-size: 1.5rem;
      }
    }
  }
  .NavItems {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      list-style-type: none;
      padding: 0 2rem;
      font-size: 1.25rem;
      a {
        font-weight: 400;
        text-decoration: none;
        color: #fff;
      }
      a.active {
        font-weight: 700;
      }
    }
  }
  .menuIcon {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 2rem;
  }
  .mobile {
    display: none;
    @media screen and (max-width: 1000px) {
      display: unset;
    }
  }
  .mobileItem {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: revert;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(
      164.53deg,
      #080808 36.99%,
      #171b22 64.11%,
      #222832 89.6%
    );
    padding-top: 3rem;
    p {
      position: absolute;
      right: 0;
      top: 0;
      padding: 2rem;
    }
    li {
      padding: 2rem;
    }
  }
  .desktop {
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
}
.nav {
  background: linear-gradient(
    180deg,
    #080808 -19.29%,
    rgba(7, 7, 7, 0.52) 3.53%,
    rgba(0, 0, 0, 0.2) 100%
  );
  backdrop-filter: blur(10px);
  @media screen and (max-width: 900px) {
    background: linear-gradient(
      180deg,
      #080808 36.99%,
      #171b22 64.11%,
      #222832 89.6%
    );
  }
}
.nav-bg {
  background: linear-gradient(
    180deg,
    #080808 36.99%,
    #171b22 64.11%,
    #222832 89.6%
  );
  // background: purple;
  transition: all 0.3s ease-in-out;
}
