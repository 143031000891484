.homeClient {
  margin-top: 6rem;
  text-align: center;
  h1 {
    font-size: 2.6rem;
    font-weight: 900;
    margin-bottom: 1rem;
    span {
      white-space: nowrap;
    }
    @media screen and (max-width: 600px) {
      font-size: 2rem;
    }
  }
  p {
    font-family: "Lora", serif;
    font-size: 1.3rem;
    line-height: 30px;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 600px) {
    margin-top: 3rem;
  }
}
.logos {
  display: flex;
  margin-top: 4rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .logoBox {
    height: 175px;
    width: 175px;
    flex: 0 0 33%;
    @media screen and (max-width: 600px) {
      flex: 0 0 100%;
    }
    img {
      width: 100%;
      height: 85%;
      object-fit: contain;
      padding: 1rem;
    }
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 40%;
  height: 40%;
  object-fit: cover;
  text-align: center;
}
.swiper {
  margin: 0 6rem;
  @media screen and (max-width: 900px) {
    margin: 0;
  }
}
.swiper-wrapper {
  align-items: center;
}
.swiper-button-prev,
.swiper-button-next {
  color: black;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px;
}
