.homeGif{
    width: 100%;
    margin-top: 4rem;
    img{
        height: 100vh;
        width: 100%;
        object-fit: cover;
        @media screen and (max-width: 600px){
            height: auto;
        }
    }
}