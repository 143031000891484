.homeQuality {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  flex-direction: column;
  @media screen and (max-width: 800px) {
    margin-top: 4rem;
  }
  h1 {
    font-size: 2.6rem;
    font-weight: 900;
    @media screen and (max-width: 800px) {
      font-size: 1.8rem;
      text-align: center;
    }
  }
  p {
    text-align: center;
    font-family: "Lora", serif;
    font-size: 1.3rem;
    padding-top: 1rem;
  }
}
.products {
  display: flex;
  justify-content: center;
  align-items: baseline;
  @media screen and (max-width: 800px) {
    align-items: center;
    flex-direction: column;
  }
  .illustartionBox {
    flex: 0 0 33%;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .illuBox {
      // background: #e1ebf5;
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 200px;
      @media screen and (max-width: 800px) {
        width: 158px;
      }
      img {
        width: 100%;
      }
    }

    h1 {
      font-weight: 700;
      font-size: 2rem;
      padding-top: 1rem;
      @media screen and (max-width: 1050px) {
        font-size: 1.5rem;
      }
      @media screen and (max-width: 800px) {
        padding-top: 0;
      }
    }
    p {
      font-family: "Lora", serif;
      font-size: 1.3rem;
      text-align: center;
      padding-top: 1rem;
      width: 80%;
      margin: 0;
      @media screen and (max-width: 800px) {
        padding-top: 0;
      }
    }
  }
}
