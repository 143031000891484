.txtileBuild {
  align-items: center;
  .backBuildingImage::before {
    background: url(../../assets/building.jpg) no-repeat center center/cover;
  }
  p {
    color: black;
    text-align: center;
    padding-top: 4rem;
    width: 77%;
    font-size: 2.6rem !important;
    @media screen and (max-width: 800px) {
      width: 100%;
      font-size: 1.8rem !important;
      line-height: 40px;
    }
  }
  button {
    background: linear-gradient(
      164.53deg,
      #080808 36.99%,
      #171b22 64.11%,
      #222832 89.6%
    );
    color: white;
    border: none;
  }
}
