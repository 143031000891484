video {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  @media screen and (max-width: 900px) {
    height: auto;
    object-fit: contain;
  }
}
.playPause {
  background: #000000ba;
  position: absolute;
  bottom: 50px;
  left: 10%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    top: 250px;
  }
  img {
    width: 60%;
    height: 60%;
    object-fit: cover;
  }
}
